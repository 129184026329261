import * as Sentry from '@sentry/react';
import sailsIOClient from 'sails.io.js/sails.io';
import socketIOClient from 'socket.io-client';
import { EventEmitter } from '@qb/frontend/utils/EventEmitter';
import { isBrowser } from '@qb/frontend/utils/env.utils';
import { UNAUTHORIZED_ERROR_CODE, BAD_REQUEST_EVENT } from './constants';

// this will console log all the received socket events to the console
const DEBUG_SOCKET = false;

// Socket api URL needs to be without any api prefixes
export const setSocketApiUrl = (url) => {
  if (isBrowser) {
    window.SOCKET_API_URL = url;
  }
};

/**
 * Connect to the Sails socket on demand.
 */
export const getSocket = () => {
  // IO Sails Socket
  if (!window.SOCKET_API_URL) {
    console.error('SOCKET_API_URL is not defined');
    Sentry.captureException(new Error('SOCKET_API_URL is not defined'));
  }

  if (!window.QBSocket) {
    const io = socketIOClient.sails
      ? socketIOClient
      : sailsIOClient(socketIOClient);
    io.sails.autoconnect = false;
    io.sails.reconnection = true;
    io.sails.environment = DEBUG_SOCKET ? 'development' : 'production';
    io.sails.url = window.SOCKET_API_URL;
    window.QBSocket = io.sails.connect();
  }

  return window.QBSocket;
};

const getSocketIfConnected = () => {
  if (window.QBSocket) {
    return window.QBSocket;
  }

  return null;
};

export const disconnectSocket = () => {
  if (window.QBSocket) {
    if (window.QBSocket.isConnected()) {
      window.QBSocket.disconnect();
    }
  }
};

export function SocketGetRequest(url, successHandler, errorHandler) {
  SocketGetRequestWithData(url, null, successHandler, errorHandler);
}

export function SocketGetRequestWithData(
  url,
  data,
  successHandler,
  errorHandler,
) {
  getSocket().get(url, data, (bodyData, response) => {
    if (response.statusCode !== 200) {
      if (response.statusCode === UNAUTHORIZED_ERROR_CODE) {
        EventEmitter.publish(BAD_REQUEST_EVENT, response.statusCode);
      } else if (errorHandler) {
        Sentry.captureException(
          new Error(response?.error || response || bodyData),
          (scope) => {
            return scope.setExtra(
              'handler',
              'SocketGetRequestWithData.errorHandler',
            );
          },
        );

        return errorHandler({
          statusCode: response.statusCode,
          message: bodyData,
        });
      }
    } else {
      if (successHandler) {
        return successHandler(bodyData);
      }
    }
  });
}

export function SocketPostRequest(url, data, successHandler, errorHandler) {
  getSocket().post(url, data, (bodyData, response) => {
    if (response.statusCode !== 200) {
      if (response.status === UNAUTHORIZED_ERROR_CODE) {
        EventEmitter.publish(BAD_REQUEST_EVENT, response.status);
      } else if (errorHandler) {
        Sentry.captureException(
          new Error(response?.error || response || bodyData),
          (scope) => {
            return scope.setTag('handler', 'SocketPostRequest.errorHandler');
          },
        );

        return errorHandler({
          statusCode: response.statusCode,
          message: bodyData,
        });
      }
    } else {
      if (successHandler) {
        return successHandler(bodyData);
      }
    }
  });
}

// For whatever reason, window.QBSocket.off doesn't work, so let's store handlers and not add duplicate handlers
let handlers = {};
export function OnMessage(resourceName, handlerToAdd) {
  // See if we've already saved the handler
  if (!handlers[resourceName]) {
    handlers[resourceName] = [];
  }
  for (let i = 0; i < handlers[resourceName].length; i++) {
    if (handlers[resourceName][i] === handlerToAdd) return;
  }
  // If we haven't saved the handler previously, then attach it now
  handlers[resourceName].push(handlerToAdd);
  getSocket().on(resourceName, (message) => {
    if (DEBUG_SOCKET) {
      console.log(`%c ${resourceName}`, 'color: #bada55', message);
    }

    for (let i = 0; i < handlers[resourceName].length; i++) {
      if (handlers[resourceName][i] === handlerToAdd)
        return handlerToAdd(message);
    }
  });
}

export function OffMessage(resourceName, handlerToRemove) {
  if (handlers[resourceName]) {
    for (let i = handlers[resourceName].length - 1; i >= 0; i--) {
      if (handlers[resourceName][i] === handlerToRemove) {
        handlers[resourceName].splice(i, 1);
      }
    }
  }
  const socket = getSocketIfConnected();
  if (!socket) {
    return;
  }
  // The following doesn't seem to work, but we'll keep it around
  return socket.off(resourceName, handlerToRemove);
}
