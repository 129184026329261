'use client';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { useGetCurrentUserInfoRequest } from '@/src/components/Auth/hooks/useGetCurrentUserInfoRequest';
import { LOGOUT_EVENT_NAME } from '@/src/components/Auth/hooks/useLogoutRequest';
import { useTenantAlias } from '@/src/hooks/useTenantAlias';
import { setUser, fetchFeatureFlips } from '@/src/store/reducers/main';
export const UserManager = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const tenantAlias = useTenantAlias();
  const {
    isSuccess,
    data
  } = useGetCurrentUserInfoRequest();
  useEffect(() => {
    dispatch(fetchFeatureFlips({
      tenantAlias
    }));
  }, [dispatch, tenantAlias]);
  useEffect(() => {
    const handleLogout = (event: StorageEvent) => {
      if (event.key === LOGOUT_EVENT_NAME) {
        dispatch(setUser(null));
        Sentry.setUser(null);
        router.push('/login');
      }
    };
    window.addEventListener('storage', handleLogout);
    return () => {
      window.removeEventListener('storage', handleLogout);
    };
  }, [dispatch, router]);
  useEffect(() => {
    if (isSuccess) {
      Sentry.setUser(data.user ? {
        id: String(data.user.id)
      } : null);
    }
  }, [data?.user, isSuccess]);
  return null;
};