import { useQuery } from '@tanstack/react-query';
import { LinearProgress, Link as MuiLink, LinkProps } from '@mui/material';
import { PreserveAspectRatioImage } from '@qb/frontend/components/PreserveAspectRatioImage';
import { spaQueries } from '@qb/httpRequest/spaQueries';
import { AppPublicSPAConfig } from '@/shared/types/controllers/spa/SPAAppControllerTypes';
import { useTenantAlias } from '@/src/hooks/useTenantAlias';
type LogoAsSvgProps = {
  height: LinkProps['height'];
  variant: 'white' | 'default';
};
export const LinkedLogoAsSvg = ({
  height,
  variant
}: LogoAsSvgProps) => {
  const tenantAlias = useTenantAlias();
  const {
    data: spaConfig,
    isLoading: isLoadingSPAConfig
  } = useQuery(spaQueries.app.publicSPAConfig(tenantAlias));
  if (isLoadingSPAConfig || !spaConfig) {
    return <LinearProgress />;
  }
  return <MuiLink href="/" title="Go to homepage" sx={{
    height: height,
    display: 'flex',
    width: 'fit-content',
    mx: 'auto'
  }} data-sentry-element="MuiLink" data-sentry-component="LinkedLogoAsSvg" data-sentry-source-file="LinkedLogoAsSvg.tsx">
      <Logo variant={variant} spaConfig={spaConfig} data-sentry-element="Logo" data-sentry-source-file="LinkedLogoAsSvg.tsx" />
    </MuiLink>;
};
type LogoProps = {
  variant: LogoAsSvgProps['variant'];
  spaConfig: AppPublicSPAConfig['spaConfig'];
};
const Logo = ({
  variant,
  spaConfig
}: LogoProps) => {
  if (variant === 'white' && spaConfig.footerLogoTarget) {
    return <PreserveAspectRatioImage src={spaConfig.footerLogoTarget} alt={`${spaConfig.company.name} Logo`} width={170} priority />;
  } else if (spaConfig.headerLogoTarget) {
    return <PreserveAspectRatioImage src={spaConfig.headerLogoTarget} alt={`${spaConfig.company.name} Logo`} width={170} priority />;
  }
  return null;
};