'use client';

import { useState, useEffect, useCallback, memo } from 'react';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { usePathname } from 'next/navigation';
import { Box } from '@mui/material';
import { useIsMobile } from '@qb/frontend/hooks/mediaQueries';
import salesPilotLogoLarge from './assets/salesPilotLogoLarge.svg';
import salesPilotLogoSmall from './assets/salesPilotLogoSmall.svg';
import { SidebarToggleIcon } from './components/SidebarToggleIcon';
const SidebarNav = dynamic(() => import('./components/SidebarNav').then(mod => mod.SidebarNav));
const SIZE_SMALL = 60;
export const SIZE_LARGE = 228;
const SIZE_HIDDEN = 0;
const PAGES_WITH_MINI_SIDEBAR = ['/conversation', '/inventory-and-pricing', '/settings'];
const PAGES_WITH_NO_SIDEBAR = ['/cart/checkout'];
const getMaxSidebarWidth = (isMobile: boolean, pathname: string) => {
  if (isMobile || PAGES_WITH_NO_SIDEBAR.some(page => pathname.startsWith(page))) {
    return SIZE_HIDDEN;
  }
  if (PAGES_WITH_MINI_SIDEBAR.some(page => pathname.startsWith(page))) {
    return SIZE_SMALL;
  }
  return SIZE_LARGE;
};
const SidebarDesktopContainerInternal = () => {
  const pathname = usePathname();
  const isMobile = useIsMobile();
  const [currentWidth, setCurrentWidth] = useState<number | null>(null);
  useEffect(() => {
    setCurrentWidth(getMaxSidebarWidth(isMobile, pathname));
  }, [isMobile, pathname]);
  const toggleSidebar = useCallback(() => {
    setCurrentWidth(currentWidth === SIZE_SMALL ? SIZE_LARGE : SIZE_SMALL);
  }, [currentWidth]);
  const isSmallSize = currentWidth === SIZE_SMALL;
  const isLargeSize = currentWidth === SIZE_LARGE;
  const isHidden = currentWidth === SIZE_HIDDEN;
  if (currentWidth === null || isHidden) {
    return null;
  }
  return <Box component="aside" data-cy="SidebarDesktopContainer" sx={theme => ({
    display: ['none', 'flex'],
    flexDirection: 'column',
    flexShrink: 0,
    flexBasis: currentWidth,
    transition: ({
      transitions
    }) => transitions.create(['flex-basis']),
    backgroundImage: `linear-gradient(${theme.palette.common.black} 70%, ${theme.palette.spaNavbar?.navbarColor})`,
    boxShadow: 1,
    zIndex: 1,
    overflowY: 'auto',
    overflowX: 'hidden'
  })} data-sentry-element="Box" data-sentry-component="SidebarDesktopContainerInternal" data-sentry-source-file="SidebarDesktopContainer.tsx">
      <SidebarNav areExtraLinksSeparate isSidebarSmallSize={isSmallSize} data-sentry-element="SidebarNav" data-sentry-source-file="SidebarDesktopContainer.tsx">
        <SidebarToggleIcon isOpen={isLargeSize} onClick={toggleSidebar} data-sentry-element="SidebarToggleIcon" data-sentry-source-file="SidebarDesktopContainer.tsx" />
      </SidebarNav>
      <Box sx={{
      mx: isSmallSize ? 1 : 2,
      mb: 2
    }} data-sentry-element="Box" data-sentry-source-file="SidebarDesktopContainer.tsx">
        <Image src={isSmallSize ? salesPilotLogoSmall : salesPilotLogoLarge} alt="Sales Pilot Logo" width={isSmallSize ? 50 : 150} height={40} data-sentry-element="Image" data-sentry-source-file="SidebarDesktopContainer.tsx" />
      </Box>
    </Box>;
};
export const SidebarDesktopContainer = memo(SidebarDesktopContainerInternal);